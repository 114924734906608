import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account-update"
export default class extends Controller {
  static targets = ["agency"]
  connect() {
    console.log("Hello, Stimulus!", this.element);
  }
  update() {
    console.log('hello, Update!', Agency.find(this.agencyTarget.value).name)
  }
}
